<template>
    <div>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-1 mt-1">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1">Mise en forme des bordereaux</h1>
        </div>
       
      </div>

      <b-card>
        <div>
          <ImportBordereauPartenaires @refresh-data="fresh" />
        </div>
        
        
        <b-overlay :show="showLoading" no-wrap />
          <div class="d-flex flex-column bd-highlight mb-3">
            <div class="p-2 bd-highlight">
              <BordereauPartenairesBbi @refresh-data="fresh" :re-affiche="reAffiche" :fiche-id = "idFicheBordereau" @find-id="findId" @find-data="findData" />
            </div>

            <div class="p-2 bd-highlight" >
              <DetailBdrPartenairesBbi @refresh-data="refreshData" :bordereau-partenaire-bbi="bordereauPartenaireBBi" :fiche-id = "idFicheBordereau"/>
            </div>
          </div>
      </b-card>
    </div>
  </template>
  
  <script>
import flatPickr from "vue-flatpickr-component";
import { BPagination, BFormGroup, VBTooltip, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BForm, BCard, BDropdown, BDropdownItem, BOverlay, BInputGroup, BInputGroupAppend, VBToggle } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import DetailBdrPartenairesBbi from './detail-bordereaux/DetailBdrPartenairesBbi'
import BordereauPartenairesBbi from './detail-bordereaux/BordereauPartenairesBbi';
import ImportBordereauPartenaires from './ImportBordereauPartenaires';



  export default {
    components: {
      VueGoodTable,
      BPagination,
      BFormGroup,
      BFormInput,
      BForm,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      BRow,
      BCol,
      BButton,
      BCard,
      BDropdown,
      BDropdownItem,
      BOverlay,
      BFormSelectOption,
      VBTooltip,
      VBToggle ,
      Ripple,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      DetailBdrPartenairesBbi,
      BordereauPartenairesBbi,
      ImportBordereauPartenaires
      
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple
    },
    data() {
      return {
        pageLength: 5,
        currentUser: JSON.parse(localStorage.getItem('userData')),
        dir: false,
        isUpdate: false,
        recherche:null,
        denomination_commercial:null,
        echeance:null,
        selectedStatut:null,
        statusOptions: [
          { value: null, text: 'Choisissez' },
          { value: 'Tous', text: ' Tous ' },
          { value: 'En compta', text: 'En compta' },
          { value: 'En GED', text: 'En GED' },
          { value: 'En anomalie', text: 'En anomalie' }
        ],
        // filter
        selected: null,
        searchTerm: '',
        showLoading: false,
        idFicheBordereau: 0,
        bordereauPartenaireBBi: {},
        reAffiche:0
      }
    },
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true
          return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
        return this.dir
      }
    },
    watch: {
      
    },
    mounted() {
    
    },
    methods: {
      findId(id) {
        this.idFicheBordereau = id
      },
      findData(data) {
        this.bordereauPartenaireBBi = data
        
      },
      fresh() {
        this.idFicheBordereau = 0
        
      },
      refreshData() {
        this.reAffiche += 1
        
      }
    }
  }
  </script>
  
  <style lang="scss">
  .vert-dote {
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
  }

  .red-dote {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }

.buttoninterface {
  width: 20%;
}

@media screen and (max-width: 800px) {
  .buttoninterface {
    width: 80%;
  }
}


  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  