<template>
    <div>

        <div>
            <b-overlay :show="showLoading" no-wrap />
            <h1 class="text-uppercase">{{ title }} </h1>
            <vue-good-table style="margin-top:20px;" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                }">
                <template slot="table-row" slot-scope="props">

                    <span class="d-flex justify-content-center" v-if="props.column.field === 'progression'">

                        <span v-if="props.row.contrat != null" v-b-tooltip.hover.v-primary
                            v-b-tooltip.hover.top="'Validé '" class="vert-dote"></span>
                        <span v-else v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Invalide'"
                            class="red-dote"></span>

                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'actions'">
                        <span v-if="props.row.contrat != null">
                            <b-button variant="primary" size="md" @click="showPopup(props.row)">
                                <span class="align-middle">Visualiser</span>
                            </b-button>
                        </span>
                        <span v-else>
                            <b-button disabled variant="primary" size="md" @click="showPopup(props.row)">
                                <span class="align-middle">Visualiser</span>
                            </b-button>
                        </span>
                    </span>
                </template>



                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <b-row class="mt-2 align-items-center">
                        <b-col md="3">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap"> Affichage 1 à </span>
                                <b-form-select v-model="pageLength" :options="['5', '10', '15']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
                                        " />
                                <span class="text-nowrap">
                                    de {{ props.total }} entrées
                                </span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2 align-items-center">
                        <b-col>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                @input="value => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </template>
                <div class="text-center align-middle" slot="emptystate">
                    <span>Aucune donn&eacute;e disponible dans le tableau</span>
                </div>
            </vue-good-table>

            <b-button :disabled="statut_fiche != 'EDITABLE'" class="buttoninterface" variant="primary" size="md"
                @click="fetchDataTraite(ficheId)">
                <feather-icon icon="PlusIcon" />
                <span class="ml-1 align-middle">Traiter le fichier</span>
            </b-button>
            <b-modal id="fiche-courtier" ref="fiche-courtier" :no-close-on-backdrop="true" hide-footer centered>
                <template #modal-title>
                    <h4 class="text-uppercase" style=" margin: 0 auto; color: #4d25bc;">Contrat </h4>
                </template>

                <b-col cols="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Denomination commercial
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.courtier.denominationCommercial }}
                            </td>
                        </tr>

                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Code
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.courtier.code }}
                            </td>
                        </tr>

                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Client
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.nomClient }}
                            </td>
                        </tr>

                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Prime
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.prime }}
                            </td>
                        </tr>

                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Taux
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.taux }}
                            </td>
                        </tr>

                         <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Commission estimée (1ère année)
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.commission_estime }}
                            </td>
                        </tr>

                        <tr>
                            <th class="pb-50">
                                <span class="font-weight-bolder">Commission estimée année suivante
                                </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ contrat.commission_suivant }}
                            </td>
                        </tr>
                    </table>
                </b-col>

            </b-modal>
        </div>

        <div class="mt-1" v-if="rowsTwo.length > 0 ">
            <b-overlay :show="showLoading" no-wrap />
            <h1>Prévisualisation Bordereau(x) BBI</h1>

            <vue-good-table style="margin-top:20px;" :columns="columnsTwo" :rows="rowsTwo" :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                }">

                <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'code'">
                        <span v-if="props.row.document_id != null">
                            <span style="cursor:pointer;">
                                <span size="md"
                                    @click="displayDocument(props.row.document_id, props.row.NomCourtier, props.row.NomCourtier)">
                                    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"
                                        id="ExcelFile">
                                        <path fill="#37b828"
                                            d="M80.016 96h-8.297L63.75 83.039 55.781 96H48l11.367-17.672-10.64-16.594h8.016l7.383 12.328 7.242-12.328h7.828L68.438 78.727 80.016 96zM104 80c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm10.882 16.988-.113.176-8.232 11.438c-.548.866-1.508 1.398-2.537 1.398s-1.989-.532-2.536-1.397l-8.346-11.614a3.01 3.01 0 0 1 .01-2.994 3.01 3.01 0 0 1 2.596-1.494H100V86c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v6.5h4.276c1.065 0 2.061.572 2.596 1.494a3.01 3.01 0 0 1 .01 2.994z"
                                            class="color007732 svgShape"></path>
                                        <path fill="#979797" d="m84 125.95-.05.05H84zM114 77v-.05l-.05.05z"
                                            class="colorff9a30 svgShape"></path>
                                        <path fill="#000000"
                                            d="M111.071 44.243 71.757 4.929A9.936 9.936 0 0 0 64.687 2H24c-5.514 0-10 4.486-10 10v104c0 5.514 4.486 10 10 10h59.95l-4-4H24c-3.309 0-6-2.691-6-6V12c0-3.309 2.691-6 6-6h40.687c1.603 0 3.109.624 4.242 1.757l39.314 39.314A6.044 6.044 0 0 1 110 51.313V72.95l4 4V51.313c0-2.67-1.04-5.181-2.929-7.07z"
                                            class="color007732 svgShape"></path>
                                        <path fill="#ffffff" d="m113.95 77 .05-.05-4-4" class="colorffffff svgShape">
                                        </path>
                                    </svg>
                                </span>
                            </span>

                        </span>
                        <span v-else>
                            {{ props.row.code }}
                        </span>
                    </span>

                </template>

                <!-- pagination -->

                <template slot="pagination-bottom" slot-scope="props">
                    <b-row class="mt-2 align-items-center">
                        <b-col md="3">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap"> Affichage 1 à </span>
                                <b-form-select v-model="pageLengthTwo" :options="['5', '10', '15']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
                                            " />
                                <span class="text-nowrap">
                                    de {{ props.total }} entrées
                                </span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2 align-items-center">
                        <b-col>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLengthTwo" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                @input="value => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </template>
                <div class="text-center align-middle" slot="emptystate">
                    <span>Aucune donn&eacute;e disponible dans le tableau</span>
                </div>
            </vue-good-table>

            <b-button :disabled="statut_fiche != 'EDITABLE'" class="buttoninterface" variant="primary" size="md"
                @click="exportFicheCourtier">
                <feather-icon icon="DownloadCloudIcon" />
                <span class="ml-1 align-middle">Générer</span>
            </b-button>

            <!-- <div class="mt-1 d-flex justify-content-end">
                <b-button class="buttoninterface" :disabled="statut_fiche != 'EDITE'" variant="primary" size="md"
                    @click="envoyeCompte(ficheId)">

                    <span class="align-middle">Envoyer vers compta</span>
                </b-button>
            </div>

            <div class="mt-1 d-flex justify-content-end">
                <b-button class="buttoninterface" :disabled="statut_fiche != 'EN_COMPTA' " variant="primary" size="md"
                    @click="envoyeCourtier(ficheId)">
                    <span class="align-middle">Envoyer aux courtier</span>
                </b-button>
            </div> -->
        </div>

    </div>
</template>
  
<script>
import flatPickr from "vue-flatpickr-component";
import { BModal, BPagination, BFormGroup, VBTooltip, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BForm, BCard, BDropdown, BDropdownItem, BOverlay, BInputGroup, BInputGroupAppend, VBToggle } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
export default {
    components: {
        VueGoodTable,
        BModal,
        BPagination,
        BFormGroup,
        BFormInput,
        BForm,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BRow,
        BCol,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BFormSelectOption,
        VBTooltip,
        VBToggle,
        Ripple,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,

    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        Ripple
    },
    data() {
        return {
            pageLength: 5,
            title: null,
            pageLengthTwo: 5,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            dir: false,
            columns: [
                {
                    label: '',
                    field: 'progression',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Echeance',
                    field: 'echeance',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Numero Contrat',
                    field: 'num_contrat',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Client',
                    field: 'nom_client',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Prime Assureur',
                    field: 'prime',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Taux Assureur',
                    field: 'taux',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Commission',
                    field: 'commision',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Match BBI',
                    field: 'actions',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                }
            ],
            columnsTwo: [
                {
                    label: 'Code',
                    field: 'code',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Dénomination commercial',
                    field: 'denomination_commercial',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Echeance',
                    field: 'echeance',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Numero Contrat',
                    field: 'num_contrat',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Client',
                    field: 'nom_client',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Prime',
                    field: 'prime',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
                {
                    label: 'Taux',
                    field: 'taux',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                }, 
                {
                    label: 'Assiette',
                    field: 'assiette',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                }, 
                {
                    label: 'Commission',
                    field: 'commision',
                    tdClass: 'align-middle',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                },
            ],
            rows: [],
            rowsTwo: [],
            searchTerm: '',
            showLoading: false,
            contrat: {
                nomClient: null,
                courtier: {
                    denominationCommercial: null,
                    code:null
                },
                prime: null,
                taux: null,
                commission_estime:null,

            },
            statut_fiche : null,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        }
    },
    watch: {
        ficheId: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val != '0' && val != null) {
                    this.fetchData(val)
                } else {
                    this.rows = []
                    this.rowsTwo = []
                    this.title = null
                }
                
            }
        },

    },
    props: {
        ficheId:  {
            type: Number,
            default: 0,
            required: false
        },
        bordereauPartenaireBbi: {
            type: Object,
            default: {},
            required: false
        }
    },
    mounted() {

    },
    methods: {
        envoyeCourtier(id) {
            this.showLoading = true
            this.$http.get(`/bordereau_bii/mailCourtier/${id}`)
                .then(res => {
                    if (res.data.success) {
                        this.$emit('refresh-data')
                        this.statut_fiche = 'EN_GED'
                    }
                    this.showLoading = false
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })
            
        },
        envoyeCompte(id) {
            this.showLoading = true
            this.$http.get(`/bordereau_bii/mailCompta/${id}`)
                .then(res => {
                    if (res.data.success) {
                        this.$emit('refresh-data')
                        this.statut_fiche = 'EN_COMPTA'
                    }
                    this.showLoading = false
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })
        },
         displayDocument(id, name, NomDuDocument) {

            var nomDocToDownload = ""
            if (NomDuDocument) {
                nomDocToDownload = NomDuDocument +'_'+ id
            } else {
                nomDocToDownload = name + '_' + id
            }
            console.log(nomDocToDownload)

            this.$http
                .get(`/document/generate_base64_for_document/${id}`)
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data.isDownload) {
                            this.downloadDocument(res.data.data.base64, nomDocToDownload)
                        }
                    } else {
                        this.messageToast(res.data.message, 'Erreur', "danger")
                    }

                })
                .catch(err => {
                    this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
                    console.error(err)
                })
        },
        downloadDocument(base64, name) {
            if (base64) {
                const a = document.createElement('a')
                a.href = base64
                a.download = name
                a.click()
                this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
            } else {
                this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            }
        },
        showPopup(data) {
            this.contrat = {
                nomClient: data.contrat.contrable.nom + ' ' + data.contrat.contrable.prenom,
                courtier: {
                    denominationCommercial: data.denomination_commercial,
                    code: data.code
                },
                prime: data.contrat.prime,
                taux: data.taux_commission,
                commission_estime: parseFloat(((data.contrat.prime * 12) / 1.1325 * 0.3).toFixed(2)),
                commission_suivant: parseFloat(((data.contrat.prime * 12) / 1.1325 * 0.1).toFixed(2)),
            };
            console.log(this.contrat)
            this.$refs['fiche-courtier'].show()
        },
        fetchData(id) {
            let type = 'detail'
            this.showLoading = true
            this.rows = []
            this.rowsTwo = []
            this.$http.get(`/bordereau_bii/fetchDataFicheDetailBordereau/${id}/${type}`)
                .then(res => {
                    if (res.data.success) {
                        this.rows = res.data.data.detailBordereaux
                        
                        if (this.rows.length > 0) {
                            this.statut_fiche = this.rows[0].bordeauxpartenairesbii.statut;
                            this.title = `Détail du bordereau ${this.rows[0].bordeauxpartenairesbii.assureur.nom_com_assureur} du ${this.rows[0].created_at} `
                        } else {
                            this.statut_fiche = null;
                            this.title = ''
                        }


                        if (this.statut_fiche != 'EDITABLE' && this.statut_fiche != 'EN_ERREUR' && this.statut_fiche != null) {
                            this.fetchDataTraite(id)
                        }
                        this.showLoading = false
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })
        },
        fetchDataTraite(id) {
            let type = 'genere'
            this.showLoading = true
            this.rowsTwo = []
            this.$http.get(`/bordereau_bii/fetchDataFicheDetailBordereau/${id}/${type}`)
                .then(res => {
                    if (res.data.success) {
                        this.rowsTwo = res.data.data.data
                        this.showLoading = false
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })
            
        },
        exportFicheCourtier() {
            this.showLoading = true
            this.$http.post("bordereau_bii/exportBordereauxCourtier", { data: this.rows })
                .then(res => {
                    if (res.data.success) {
                        this.showLoading = false
                        this.statut_fiche = 'EDITE'
                        this.fetchDataTraite(this.ficheId)
                        this.$emit('refresh-data')
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })
            
        }
    }
}
</script>
  
<style lang="scss">

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
  