<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" size="md" class="buttoninterface" @click="showModal">
                <feather-icon icon="UploadCloudIcon" />
                <span class="ml-1 align-middle">Import fichier bordereau</span>
            </b-button>
        </div>
        <b-modal id="gestion-bordereau" ref="gestion-bordereau" size="lg" title="Importer Bordereau "
            :no-close-on-backdrop="true" centered @hide="clearDataExcel">
        <b-overlay :show="showLoading" no-wrap />

            <div class="px-1 d-flex bd-highlight">
                <div class="w-100 bd-highlight">
                    <b-form-group class="pt-1" label="Document bordereau partenaire *" label-for="h-type" @click="openFileExel">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="'Charger un ficher'"
                                    class="btn-icon" variant="outline-primary" @click="openFileExel">
                                    <b-form-file id="fileClientExel" v-model="fileClientExel" type="file" class="d-none"
                                        accept=".xlsx, .xls , .xlsm, .xlsb, .xltx" />
                                    <feather-icon icon="UploadCloudIcon" />
                                </b-button>
                            </b-input-group-prepend>
                            <b-form-input disabled placeholder="Importer votre bordereau"
                                :value="fileClientExel ? fileClientExel.name : ''" />
                        </b-input-group>
                    </b-form-group>

                   
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button :disabled="validation" variant="primary" style="font-size:12px" @click="recordDocumentExcel">
                        Importer
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BOverlay,
    VBTooltip,
    BFormFile,
    BModal,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    VBToggle,
    BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

export default {
    components: {
        BButton,
        Cleave,
        BOverlay,
        BFormFile,
        BInputGroupPrepend,
        BModal,
        BFormGroup,
        BInputGroup,
        BFormInput,
    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            fileClientExel: null,
            validation: true,
            showLoading: false,
        }
    },
    props: {
      
    },
    watch: {
        fileClientExel() {
            this.validationCheck()
        },
       
    },
    methods: {
        validationCheck() {
            if (this.fileClientExel != null && this.fileClientExel != '') {
                this.validation = false
            } else {
                this.validation = true
            }  
        },
        showModal() {
            this.$refs['gestion-bordereau'].show()
        },
        clearDataExcel() {
            this.fileClientExel = null
           
        },
        recordDocumentExcel() {
            this.showLoading = true
            const formData = new FormData()

            formData.append('file', this.fileClientExel)
            formData.append('assureur_id', '3')


            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
                .post('/bordereau_bii/importBordeauxPartenaireBii', formData, config)
                .then(res => {
                    if (res.data.success) {
                        this.showLoading = false
                        this.$refs['gestion-bordereau'].hide()
                        this.clearDataExcel()
                        this.$emit('refresh-data')
                        this.messageToast('Import bien passe', 'Succès', 'success')
                    } else {
                        this.showLoading = false
                        this.messageToast("Import n'a pas bien passe", 'Erreur', 'error')
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                })
        },
        openFileExel() {
            document.getElementById('fileClientExel').click()
        },
    },
}
</script>


